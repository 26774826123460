import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import DetailLayout2Col from '../../containers/layout/detail-layout-2-col'
import BoxImageLarge from '../../containers/elements/box-large-image/section-home'

const ArticleDetail = ({ data, location, pageContext }) => (
  <DetailLayout2Col
    location={location}
    pageContext={pageContext}
    pageData={data.page.edges[0].node}
    related={data.related.edges}
    path="/actueel/nieuws"
    relatedTitle="Gerelateerd nieuws"
  >
    {data.latest && data.latest.edges.length > 0 && (
      <BoxImageLarge
        path="/actueel/publicaties"
        title="Recente publicaties"
        buttontext="publicaties"
        items={data.latest.edges.map((edge) => edge.node)}
        padding={!data.latest.edges.length}
        split={!data.latest.edges.length}
      />
    )}
  </DetailLayout2Col>
)

export const query = graphql`
  query ArticleDetailQuery($id: String, $categories: [String]) {
    page: allContentfulPublicatie(filter: { id: { eq: $id } }) {
      edges {
        node {
          id
          title
          date(locale: "nl-NL", formatString: "DD MMMM YYYY")
          description {
            description
            childMarkdownRemark {
              html
            }
          }
          content {
            json
          }
          themes {
            id
            title
          }
          image {
            file {
              url
              fileName
              contentType
            }
            fluid(maxWidth: 900) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          contact {
            id
            type
            name
            title
            township
            phone
            mobile
            email
            image {
              fixed(
                width: 100
                height: 100
                cropFocus: FACE
                resizingBehavior: FILL
              ) {
                ...GatsbyContentfulFixed_withWebp
              }
            }
          }
          downloads {
            id
            title
            description
            file {
              url
              fileName
              contentType
              details {
                size
              }
            }
          }
        }
      }
    }

    related: allContentfulNieuwsbericht(
      limit: 4
      sort: { fields: date, order: DESC }
      filter: {
        id: { ne: $id }
        themes: { elemMatch: { title: { in: $categories } } }
        title: { ne: null }
        slug: { regex: "/^(?!untitled-entry).+/" }
        date: { ne: null }
      }
    ) {
      edges {
        node {
          id
          title
          date(locale: "nl-NL", formatString: "DD MMMM YYYY")
          slug
          themes {
            title
          }
          image {
            fluid(maxWidth: 60, cropFocus: CENTER) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }

    latest: allContentfulPublicatie(
      limit: 4
      sort: { fields: date, order: DESC }
      filter: {
        title: { ne: null }
        slug: { regex: "/^(?!untitled-entry).+/" }
        date: { ne: null }
      }
    ) {
      edges {
        node {
          id
          title
          date(locale: "nl-NL", formatString: "DD MMMM YYYY")
          slug
          themes {
            title
          }
          image {
            fluid(maxWidth: 260, cropFocus: CENTER) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`

ArticleDetail.propTypes = {
  headingStyle: PropTypes.object,
}

export default ArticleDetail
